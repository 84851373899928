@import "nlib/config";

.step {
  font-size: 1rem;
  .subtitle {
    margin-bottom: $contentDefaultIndent * 2;
    color: darken($uiDarkColor, 28%);
    font-size: 1.125rem;
    text-align: left;
  }
  .content {
    display: flex;
    .text {
      flex: auto;
      ol {
        margin: 0 0 $contentDefaultIndent * 2 $contentDefaultIndent * 2;
        padding: 0;
      }
    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    margin-left: $contentDefaultIndent * 2;
    .okButton {
      min-width: 120px;
      padding: 0 $contentDefaultIndent * 3;
    }
  }
}
