@import "nlib/config";
@import "animations";

.navbar {
  position: relative;
  z-index: 22;
  top: 0;
  left: 0;
  box-sizing: content-box;
  width: 4.5rem;
  min-width: 4.5rem;
  height: 100%;
  transition: width 0.2s;  // stylelint-disable-line plugin/no-low-performance-animation-properties
  .logoWrap {
    display: flex;
    align-items: center;
    height: 62px;
    min-height: 62px;
    margin-bottom: 10px;
    padding: $contentDefaultIndent;
    .logo {
      display: flex;
      justify-content: center;
      width: 100%;
      max-height: 32px;
      img {
        max-width: 3.25rem;
        height: 100%;
        max-height: 2rem;
      }
    }
    .organization {
      overflow: hidden;
      font-size: 1rem;
      font-weight: 500;
      text-overflow: ellipsis;
    }
  }
  .navItem {
    display: block;
    position: relative;
    height: 3.375rem;
    min-height: 2.5rem;
    color: $uiFontColor;
    text-decoration: none;
    cursor: pointer;
    .content {
      display: flex;
      position: relative;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 $contentDefaultIndent * 1.5;
      overflow: hidden;
    }
    .notification {
      position: absolute;
      top: 50%;
      right: 0;
      min-width: 21px;
      max-width: 3.25rem;
      height: 16px;
      margin-left: $contentDefaultIndent;
      padding-top: 0;
      padding-bottom: 0;
      transform: translate3d(-15px, -19px, 0);
      transition: transform 0.2s;
      line-height: 16px;
      text-overflow: ellipsis;
      &.animated {
        animation: blink 1s ease-out infinite;
      }
    }
    .icon {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      margin-left: $contentDefaultIndent * 1.5;
      svg {
        min-width: 1.375rem;
        font-size: 1.375rem;
      }
    }
    .text {
      flex: auto;
      margin-left: $contentDefaultIndent / 2;
      overflow: hidden;
      transition: opacity 0.2s;
      opacity: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.uploading {
      svg {
        color: $uiWarningColor;
      }
      .notification {
        animation: blink 1s ease-out infinite;
        background-color: $uiWarningColor;
      }
    }
    &:hover {
      color: $uiPrimaryColor;
      text-decoration: none;
    }
    &.active {
      color: $uiPrimaryColor;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        border-radius: 3px 0 0 3px;
        background-color: $uiPrimaryColor;
      }
    }
  }
  .inner {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: width 0.2s; // stylelint-disable-line plugin/no-low-performance-animation-properties
    background-color: $uiWhiteColor;
    box-shadow: 1px 0 0 $uiBorderColor;
    &:hover {
      width: $navBarFullWidth;
      .navItem {
        .text {
          opacity: 1;
        }
        .notification {
          transform: translate3d(-12px, -8px, 0);
        }
      }
      .extension {
        .title {
          display: none;
        }
        .description {
          display: block;
        }
        .button {
          width: 80%;
          padding: 0 $contentDefaultIndent * 2;
          span {
            display: initial;
          }
        }
      }
    }
  }
  .extension {
    display: block;
    position: relative;
    width: 100%;
    margin-top: auto;
    padding: $contentDefaultIndent * 2.5 $contentDefaultIndent / 2 $contentDefaultIndent;
    color: inherit;
    text-decoration: none;
    .wrap {
      padding: $contentDefaultIndent * 3 0 $contentDefaultIndent;
      overflow: hidden;
      border-radius: $contentDefaultIndent / 2;
      background-color: $uiBackgroundColor;
    }
    .icon {
      display: flex;
      position: absolute;
      top: 0;
      left: 50%;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      transform: translate(-50%, 0);
      border-radius: 50%;
      background-color: $uiWhiteColor;
      filter: drop-shadow(0 5px 20px rgba(158, 162, 171, 0.15));
      img {
        width: 1rem;
      }
    }
    .title {
      max-width: 60px;
      height: auto;
      margin: auto;
      padding: 0;
      font-size: 0.625rem;
      text-align: center;
    }
    .description {
      display: none;
      width: 100%;
      margin-top: $contentDefaultIndent / 2;
      font-size: 0.625rem;
      text-align: center;
    }
    .button {
      display: block;
      width: 2rem;
      height: 2rem;
      margin: auto;
      margin-top: $contentDefaultIndent;
      padding: 0;
      svg {
        margin: 0 0.3em;
        font-size: 1rem;
      }
      span {
        display: none;
      }
    }
  }
}

@media (min-width: $menuFullWidthMinViewport) {
  body:not([data-document-preview]) {
    .navbar {
      width: 8%;
      min-width: $navBarFullWidth;
      max-width: $navBarFullWidth * 1.25;
      .inner {
        width: 100%;
        &:hover {
          width: 100%;
        }
      }
      .navItem {
        .text {
          opacity: 1;
        }
        .notification {
          transform: translate3d(-12px, -8px, 0);
        }
      }
      .extension {
        .title {
          display: none;
        }
        .description {
          display: block;
        }
        .button {
          width: 80%;
          padding: 0 $contentDefaultIndent * 2;
          span {
            display: initial;
          }
        }
      }
    }
  }
}

body {
  &[data-extension-installed] {
    .navbar {
      .extension {
        display: none;
      }
    }
  }
}
