@import "nlib/config";

.tooltip {
  position: fixed;
  z-index: 1051 !important;
  left: 50%;
  max-width: 260px;
  padding: $contentDefaultIndent / 2 $contentDefaultIndent;
  transform: translate(-50%, -100%) !important;
  animation-name: fadeIn;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08), -2px 2px 4px rgba(0, 0, 0, 0.08);
  font-size: 12px;
  font-weight: 500 !important;
  text-align: center;
  word-wrap: break-word;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: $uiWhiteColor transparent transparent;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2));;
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  99% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
