@import "nlib/config";
@import "animations";

.onboarding {
  display: flex;
  position: relative;
  flex: auto;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  padding: 0 $contentDefaultIndent * 2;
  overflow: auto;
  background-color: $uiWhiteColor;
  .container {
    display: flex;
    flex: auto;
    align-items: center;
    margin: 0 auto;
    padding: $contentDefaultIndent * 2;
    text-align: left;
  }
  .card {
    width: 882px;
    max-width: 100%;
    height: fit-content;
    padding: 12px 60px 60px;
    border-radius: $contentDefaultIndent;
    background-color: $uiWhiteColor;
  }
  .title {
    position: relative;
    height: 40px;
    margin-top: $contentDefaultIndent * 1.5;
    margin-bottom: $contentDefaultIndent * 3;
    color: darken($uiDarkColor, 28%);
    font-size: 1.5rem;
    text-align: center;
    .closeButton {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      align-items: center;
      svg {
        font-size: 1.2em;
        + span {
          margin-left: 0.3em;
        }
      }
    }
  }
  .subtitle {
    font-size: 1.125rem;
    text-align: left;
  }
  .buttons {
    display: flex;
    position: relative;
    margin-top: $contentDefaultIndent * 2;
    .block {
      width: 100%;
    }
    .label {
      margin-bottom: $contentDefaultIndent;
    }
    .button {
      flex: auto;
      width: 1px;
      svg {
        min-width: 1rem;
        margin-right: 0.3em;
        color: inherit;
        font-size: 1rem;
      }
      + .button {
        margin-left: 8px;
        font-size: 0.75rem;
      }
    }
    + .row {
      margin-top: $contentDefaultIndent * 2;
    }
  }

  .progress {
    position: relative;
    height: 8px;
    margin-top: $contentDefaultIndent * 2;
    overflow: hidden;
    border-radius: 4px;
    background-color: $uiBorderColor;
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translate(-100%, 0);
      transition: transform ease-out 3s;
      border-radius: 4px;
      background-color: $uiHighlightColor;
    }
    &.animated {
      div {
        animation: progressInfinity 6s ease-out infinite;
      }
    }
    &.animatedInfinity {
      div {
        animation: progressInfinity 12s linear infinite;
      }
    }
  }
  .description {
    margin-top: $contentDefaultIndent * 1.5;
    color: $uiMediumDarkColor;
    font-size: 1rem;
    svg {
      font-size: 1.25rem;
    }
  }
  .likeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21%;
    height: $contentDefaultIndent * 5;
    margin-left: $contentDefaultIndent * 2;
    padding: 0 $contentDefaultIndent * 1.5;
    border: 1px solid $uiBorderColor;
    border-radius: $contentDefaultIndent / 2;
    svg {
      font-size: 1rem;
    }
    > span {
      flex: auto;
      text-align: center;
    }
    &.success {
      border-color: $uiPositiveColor;
      background-color: $uiPositiveColor;
      color: $uiWhiteColor;
    }
  }
  .error,
  .warning {
    display: flex;
    align-items: center;
    margin-top: $contentDefaultIndent * 2;
    padding: $contentDefaultIndent * 1.5;
    border-radius: $contentDefaultIndent / 2;
    background-color: lighten($uiNegativeColor, 52%);
    svg {
      min-width: 1rem;
      margin-right: $contentDefaultIndent;
      color: $uiNegativeColor;
      font-size: 1rem;
    }
    .text {
      margin-top: 0;
      margin-right: $contentDefaultIndent * 2;
      margin-left: 0;
      overflow: hidden;
      font-size: inherit;
      text-overflow: ellipsis;
    }
    .close {
      margin-left: auto;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }
  .warning {
    background-color: lighten($uiWarningColor, 47%);
    svg {
      color: $uiWarningColor;
    }
  }
  .row {
    display: flex;
    align-items: center;
    .col {
      flex: 1;
    }
  }
  .stepFirst {
    .buttons {
      .button {
        max-width: 25%;
      }
    }
  }
  .stepThird {
    .buttons {
      .button {
        width: auto !important;
      }
    }
  }
  .stepFourth {
    .text {
      .copy {
        display: inline-block;
        margin: 0 0.3em;
        padding: 0 0.3em;
        border-radius: 0.25em;
        background-color: lighten($uiHighlightColor, 45%);
        color: $uiBlackColor;
        cursor: pointer;
        svg {
          color: $uiHighlightColor;
          font-size: 1rem;
        }
      }
      .link {
        text-decoration: none;
      }
    }
  }
  &.reconnect {
    .stepFirst,
    .stepSecond,
    .stepThird,
    .stepFifth {
      display: none;
    }
    .step {
      .number,
      .line {
        display: none;
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: $contentDefaultIndent;
    .okButton {
      min-width: 120px;
      padding: 0 $contentDefaultIndent * 3;
    }
  }
  p {
    margin: 0 0 $contentDefaultIndent * 2;
  }
  .text {
    margin-top: $contentDefaultIndent;
    font-size: 1rem;
  }

  [data-wait] {
    animation: rotate 1s linear infinite;
  }
}

@keyframes progress {
  from {
    transform: translate(-100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes progressInfinity {
  from {
    transform: translate(-100%, 0);
  }
  to {
    transform: translate(100%, 0);
  }
}
