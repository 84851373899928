@import "nlib/config";

.notifications {
  .toggle {
    position: relative;
    color: $uiNeutralColor;
    cursor: default;
    svg {
      font-size: 1.5rem;
    }
    .counter {
      position: absolute;
      top: -8px;
      right: -8px;
      padding: 0 4px;
    }
    &.hasUnread {
      color: $uiDarkColor;
      cursor: pointer;
    }
  }
}
.dropdownContent {
  width: auto;
  max-width: 25rem;
  padding: $contentDefaultIndent;
  .top {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
    border-bottom: 1px solid $uiBorderColor;
    .title {
      margin-right: $contentDefaultIndent * 2;
      font-size: 1rem;
      font-weight: 500;
      white-space: nowrap;
      > svg {
        font-size: 1.2em;
      }
    }
  }
  .content {
    position: relative;
    max-height: 22rem;
    margin-top: -1px;
    overflow: auto;
  }
  .menuItem {
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid $uiBorderColor;
    .mainInfo {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;
      .title {
        display: flex;
        flex: auto;
        align-items: center;
        margin-right: $contentDefaultIndent * 2;
        overflow: hidden;
        .icon {
          display: flex;
          margin-right: 0.3em;
          svg {
            font-size: 1rem;
          }
        }
        .info {
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
        }
      }
      .counter {
        display: flex;
        min-width: 22px;
        margin: 0;
        padding: 0 4px;
      }
    }
    .text {
      max-width: 100%;
      padding: $contentDefaultIndent 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .footer {
      color: $uiMediumDarkColor;
      font-size: 0.6875rem;
    }
  }
}
[data-mobile] {
  .dropdownContent {
    padding-top: $contentDefaultIndent * 6;
  }
}
