@import "nlib/config";

.step {
  .form {
    display: flex;
    margin: $contentDefaultIndent * 2 -1 * $contentDefaultIndent 0;
  }
  .tagsInput {
    width: 100%;
    max-width: 648px;
  }
  .dateWrap {
    position: relative;
    .tooltip {
      color: $uiNegativeColor;
      font-weight: 500;
      white-space: nowrap;
      svg {
        font-size: 14px;
      }
    }
  }
  .label {
    margin-top: $contentDefaultIndent;
    margin-bottom: $contentDefaultIndent / 2;
  }
  .excludeWords {
    position: relative;
    .excludeWordsHint {
      width: 230px;
      text-align: center;
    }
  }
}
