@import "nlib/config";

.languageSelector {
  position: relative;
  .selectButton {
    border-color: transparent;
    font-weight: bold;
  }
}

[data-desktop] {
  .languageSelector {
    .dropDown {
      width: auto !important;
    }
    &.superAdminAccess {
      .selectButton:not(:disabled) {
        border-color: $uiDarkColor;
        background-color: $uiWarningColor;
      }
    }
  }
}

[data-mobile] {
  .languageSelector {
    .dropDown {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
