@import "nlib/config";

.askDiscoverySourceModal {
  z-index: 1000;
  width: 24rem !important;
  .content {
    .variants {
      display: flex;
      flex-wrap: wrap;
      margin: $contentDefaultIndent 0 $contentDefaultIndent * 2 0;
      input {
        margin: 0 0 -1px 0;
        cursor: pointer;
      }
      label {
        display: flex;
        align-items: center;
        width: 42%;
        margin-top: $contentDefaultIndent;
        font-weight: 500;
        cursor: pointer;
      }
      :global(.custom) {
        &-control-description {
          width: 100%;
        }
      }
    }
    .input {
      display: block;
      width: 100%;
      margin-top: $contentDefaultIndent / 2;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    button {
      + button {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
@media (max-width: $viewportSizeExtraSmall) {
  .askDiscoverySourceModal {
    .content {
      .variants {
        label {
          width: 100%;
        }
      }
    }
  }
}
