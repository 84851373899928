@import "nlib/config";

.step {
  .text {
    > div {
      + div {
        margin-top: $contentDefaultIndent * 2;
      }
    }
  }
  .select {
    margin-top: $contentDefaultIndent;
  }
}
