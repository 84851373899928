@import "nlib/config";

.addQuickBooksDesktopBlock {
  .title {
    margin-bottom: $contentDefaultIndent;
    color: darken($uiDarkColor, 28%);
    font-size: 1.125rem;
    text-align: left;
  }
  .text {
    font-size: 1rem;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
  }
}
