@import "nlib/config";

.radio {
  display: flex;
  align-items: flex-start;
  .input {
    appearance: none;
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    margin-bottom: auto;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border: 2px solid $uiNeutralColor;
    border-radius: 50%;
    box-shadow: inset 0 0 0 0 $uiWhiteColor;
    &:checked {
      border-color: $uiPrimaryColor;
      background-color: $uiPrimaryColor;
      box-shadow: inset 0 0 0 2px $uiWhiteColor;
    }
  }
  .label {
    flex: auto;
    margin-left: 0.3em;
    padding-top: 0.1em;
  }
}
