@import "nlib/config";

.form {
  display: flex;
  margin-top: $contentDefaultIndent * 2;
  .col {
    flex: 4;
    + .col {
      flex: 1;
      margin-left: $contentDefaultIndent * 2;
    }
  }
  .inputWrap {
    position: relative;
  }
  .accountantUserEmailError {
    min-width: 18em;
    color: $uiNegativeColor;
  }
}
