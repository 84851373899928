@import "nlib/config";

.header {
  display: flex;
  align-items: center;
  min-height: $appHeaderHeight;
  padding: 0 $contentDefaultIndent * 2;
  border-bottom: 1px solid $uiBorderColor;
  background-color: $uiWhiteColor;
  &.superAdminAccess {
    background-image: linear-gradient(225deg, $uiWarningColor 1em, $uiWhiteColor 0);
  }
  .logo {
    display: flex;
    align-items: center;
    min-width: fit-content;
    margin-right: $contentDefaultIndent * 2;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    img {
      visibility: hidden;
      max-width: 3.25rem;
      height: 100%;
      max-height: 2rem;
      opacity: 0;
    }
  }
  .businessSelector {
    flex: 1;
    max-width: 21rem;
    margin-right: $contentDefaultIndent * 2;
  }
  .aside {
    display: flex;
    align-items: center;
    margin-left: auto;
    .item + .item {
      margin-left: $contentDefaultIndent * 3;
    }
  }
  .settingsIcon {
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
      color: $uiPrimaryColor;
    }
  }
}
