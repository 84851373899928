@import "nlib/config";

.showIntroButton {
  padding: 0;
  color: $uiMediumDarkColor;
  cursor: pointer;
  svg {
    font-size: 2rem;
  }
}
