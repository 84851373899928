@import "nlib/config";

body[data-desktop] {
  background-color: $uiBackgroundColor;
  color: $uiFontColor;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $uiBackgroundColor;
  color: $uiFontColor;
  svg {
    min-width: 1em;
  }
  .outer {
    display: flex;
    flex: 1;
    min-width: 48rem;
    overflow: hidden;
    .inner {
      display: flex;
      flex: auto;
      flex-direction: column;
      overflow: hidden;
      .wrap {
        display: flex;
        position: relative;
        z-index: 1;
        flex: auto;
        overflow: hidden;
        .page {
          display: flex;
          position: relative;
          flex: 9;
          overflow: hidden;
        }
      }
    }
    .preloader {
      flex: 1;
      height: 100%;
    }
  }
}
[data-tooltip] {
  * {
    pointer-events: none;
  }
}
// For gDrive picker
.picker {
  &-dialog {
    z-index: 2500 !important;
    &-bg {
      z-index: 2000 !important;
    }
  }
}
