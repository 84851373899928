@import "nlib/config";

.infoBanner {
  padding: $contentDefaultIndent * 2;
  background-color: #ffefca;
  font-weight: 500;
  .icon {
    font-size: 1.2em;
  }
}
