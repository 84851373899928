@import "nlib/config";

.step {
  display: flex;
  position: relative;
  align-items: center;
  color: $uiMediumDarkColor;
  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    margin-right: 2rem;
    border: 1px solid $uiBorderColor;
    border-radius: 50%;
    font-size: 0.875rem;
  }
  .line {
    position: absolute;
    top: 0;
    left: 1rem;
    width: 1px;
    height: 100%;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      background: $uiBorderColor;
    }
    &::after {
      top: calc(50% + 1rem);
      bottom: 0;
    }
  }
  .content {
    flex: auto;
    padding: 1.5rem;
    border: 1px solid $uiBorderColor;
    border-radius: $contentDefaultIndent / 2;
  }
  + .step {
    margin-top: 1.5rem;
    .line {
      &::before {
        top: -1.5rem;
        bottom: calc(50% + 1rem);
      }
    }
  }
  &:last-child {
    .line {
      &::after {
        display: none;
      }
    }
  }
  &.active {
    .number {
      border-color: $uiHighlightColor;
      color: $uiHighlightColor;
    }
    .line {
      &::before,
      &::after {
        background-color: $uiHighlightColor;
        background-image: none;
      }
    }
    .content {
      border-color: $uiHighlightColor;
    }
    + .step {
      .line {
        &::before {
          background-image: linear-gradient($uiHighlightColor, $uiBorderColor);
        }
      }
    }
    + .error {
      .line {
        &::before {
          background-image: linear-gradient($uiHighlightColor, $uiNegativeColor) !important;
        }
      }
    }
  }
  &.success {
    .number {
      border-color: $uiPositiveColor;
      color: $uiPositiveColor;
    }
    .line {
      &::before,
      &::after {
        background-color: $uiPositiveColor;
        background-image: none !important;
      }
    }
    .content {
      border-color: $uiPositiveColor;
    }
    + .step {
      .line {
        &::before {
          background-image: linear-gradient($uiPositiveColor, $uiHighlightColor);
        }
      }
    }
  }
  &.error {
    .number {
      border-color: $uiNegativeColor;
      color: $uiNegativeColor;
    }
    .line {
      &::before,
      &::after {
        background-color: $uiNegativeColor;
        background-image: none !important;
      }
    }
    .content {
      border-color: $uiNegativeColor;
    }
    + .step {
      .line {
        &::before {
          background-image: linear-gradient($uiNegativeColor, $uiBorderColor);
        }
      }
    }
  }
  &.error,
  &.active,
  &.success {
    color: darken($uiDarkColor, 10%);
  }
}
